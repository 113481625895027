import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Discount } from '../models/discount.model';
import { DiscountService } from '../services/discount.service';

@Component({
  selector: 'app-discount-summary',
  templateUrl: './discount-summary.component.html',
  styleUrls: ['./discount-summary.component.scss'],
})
export class DiscountSummaryComponent implements OnInit, OnDestroy {
  constructor(public discountService: DiscountService, public translateService: TranslateService) {}

  @Input() discountData: Discount;
  @Input() showName = false;
  @Input() currentStep?: string;
  @Input() updateSummary: Observable<void>;

  public discountSummary: string[] = [];
  public discountType: string;
  public subscriptions: Subscription;

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.discountType = this.discountService.getDiscountType(this.discountData);
    this.createDiscountSummary();
    this.subscriptions.add(this.updateSummary ? this.updateSummary.subscribe(() => this.update()) : null);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public update() {
    this.discountType = this.discountService.getDiscountType(this.discountData);
    this.createDiscountSummary();
  }

  public createDiscountSummary(): void {
    const nameMessage = this.getNameMessage();
    const requireMessage = this.getRequireMessage();
    const limitMessage = this.getLimitMessage();
    let typeSpecificMessages: string[] = [];

    if (this.discountType === 'individual') {
      typeSpecificMessages = this.getIndividualDiscountMessages();
    }
    if (this.discountType === 'reusable') {
      typeSpecificMessages = this.getReusableDiscountMessages();
    }
    if (this.discountType === 'automatic') {
      typeSpecificMessages = this.getAutomaticDiscountMessages();
    }

    this.discountSummary = [nameMessage, ...typeSpecificMessages, requireMessage, limitMessage].filter(
      (summary) => summary
    );
  }

  private getIndividualDiscountMessages(): string[] {
    const codeMessage = this.getCodeMessage();
    const valueMessage = this.getValueMessage();
    return [valueMessage, codeMessage];
  }

  private getReusableDiscountMessages(): string[] {
    const codeMessage = this.getCodeMessage();
    const valueMessage = this.getValueMessage();
    return [codeMessage, valueMessage];
  }

  public getAutomaticDiscountMessages(): string[] {
    const valueMessage = this.getValueMessage();
    return [valueMessage];
  }

  public getNameMessage(): string {
    if (!this.showName || !this.discountType) {
      return '';
    }
    if (!this.discountData.name) {
      return this.translateService.instant(`DISCOUNTS.SUMMARY.NAME.no_name.${this.discountType}`);
    } else {
      return this.translateService.instant(`DISCOUNTS.SUMMARY.NAME.name.${this.discountType}`, {
        name: this.discountData.name,
      });
    }
  }

  private getCodeMessage(): string {
    let description = '';

    if (this.discountType === 'individual' && this.discountData.code_count) {
      const codeCount = this.discountData.code_count;
      const usedCodeCount = this.discountData.used_code_count;
      description = this.discountData.id
        ? this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual', {
            amount: codeCount,
            usedCodesAmount: usedCodeCount,
          })
        : this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_dynamic', { amount: codeCount });
    }
    if (this.discountType === 'reusable' && this.discountData.supplied_code) {
      const suppliedCode = this.discountData.supplied_code.toUpperCase();
      description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.reusable', { code: suppliedCode });
    }

    return description;
  }

  public getValueMessage(): string {
    const valueMessage = this.discountService.getValueMessage(this.discountData);
    if (!valueMessage || !this.discountData.discount_type) {
      return '';
    }
    const valueSummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.summary', { value: valueMessage });

    let scopeSummary = '';
    if (this.discountData.discount_scope) {
      if (this.discountData.discount_scope === 'Order') {
        scopeSummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.unitemized');
      }
      if (this.discountData.discount_scope === 'Item' && this.discountData.discounted_items) {
        const itemCount = this.discountData.discounted_items.split(',').length;
        const itemizedKey = itemCount === 1 ? 'itemized_single' : 'itemized_plural';
        scopeSummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.' + itemizedKey, { itemCount });
        if (this.discountData.max_eligible_items != null) {
          let eligibilitySummary = '';
          switch (this.discountData.max_eligible_items) {
            case 0:
              eligibilitySummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.eligible_all');
              break;
            case 1:
              eligibilitySummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.eligible_one');
              break;
            default:
              eligibilitySummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.eligible_set', {
                eligibleCount: this.discountData.max_eligible_items,
              });
              break;
          }
          scopeSummary = scopeSummary + ' ' + eligibilitySummary;
        }
      }
    }
    return valueSummary + ' ' + scopeSummary;
  }

  public getRequireMessage(): string {
    if (this.discountData.required_items && this.discountData.required_items_quantity) {
      const totalSaleItems = this.discountData.required_items.split(',').length;
      const translatePrompt = 'DISCOUNTS.SUMMARY.REQUIRE.min_quantity_' + (totalSaleItems === 1 ? 'single' : 'plural');
      return this.translateService.instant(translatePrompt, {
        requiredQuantity: this.discountData.required_items_quantity,
        totalSaleItems,
      });
    } else if (parseFloat(this.discountData.minimum_sale_total) > 0) {
      return this.discountService.getMinValueMessage(this.discountData);
    } else {
      return '';
    }
  }

  private getLimitMessage(): string {
    const hasCodeCount = this.discountData.code_count > 0;
    const hasCurrentStep = this.currentStep === 'discount_dates' || this.currentStep === 'code_count';
    let description = '';
    let translationKey = '';

    // individual discounts
    if (this.discountType === 'individual') {
      if (!this.discountData.starts_at) {
        return description;
      }
      if (!this.discountData.expires_at && !hasCodeCount && !hasCurrentStep) {
        return description;
      }
    } else {
      // reusable or automatic discounts
      if (!this.discountData.max_use || this.discountData.max_use.length < 1) {
        return description;
      }
    }

    const codeDates = this.discountService.getActiveDates(this.discountData);
    const useLimitDescriptors = this.getUseLimitDescriptors();

    // individual discounts
    if (this.discountType === 'individual') {
      translationKey = this.discountData.expires_at
        ? 'DISCOUNTS.SUMMARY.LIMIT.individual.with_dates'
        : 'DISCOUNTS.SUMMARY.LIMIT.individual.no_end_date';
      description = this.translateService.instant(translationKey, {
        only: useLimitDescriptors[0],
        between: useLimitDescriptors[1],
        dates: codeDates,
      });
    } else {
      // reusable or automatic discounts
      let amount = '';
      if (this.discountData.max_use === 'unlimited') {
        amount = this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.unlimited');
      } else {
        amount = this.discountData.max_use_count
          ? this.discountData.max_use_count
          : this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.limited');
      }

      if (!!this.discountData.expires_at) {
        translationKey = 'DISCOUNTS.SUMMARY.LIMIT.reusable.with_dates';
      } else {
        translationKey = this.discountData.id
          ? 'DISCOUNTS.SUMMARY.LIMIT.reusable.no_end_date'
          : 'DISCOUNTS.SUMMARY.LIMIT.reusable.no_dates';
      }
      description = this.translateService.instant(translationKey, {
        only: useLimitDescriptors[0],
        between: useLimitDescriptors[1],
        amount,
        dates: codeDates,
      });
    }
    return description;
  }

  private getUseLimitDescriptors(): string[] {
    const descriptors = [];
    if (this.discountType === 'individual') {
      descriptors[0] = this.discountData.expires_at
        ? this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.only')
        : '';
    } else {
      descriptors[0] =
        this.discountData.max_use === 'limited' ? this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.only') : '';
    }
    descriptors[1] = this.discountData.expires_at
      ? this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.between')
      : '';
    return descriptors;
  }
}
