<se-fe-page [seFeDataLoaded]="true" SeFeDataWidth="full">
  <se-fe-header seFePageHeader [seFeDataTitle]="'DISCOUNTS.LIST_VIEW.title' | translate" size="750">
    <ng-container seFeHeaderSubtitle *ngIf="!pageLoading">
      {{ discountHeaderSubtitle }}
      <a [href]="financialSettingsUrl()" class="pl-link" target="_top">
        {{ 'DISCOUNTS.LIST_VIEW.edit' | translate }}
      </a>
    </ng-container>
    <se-fe-button seFeHeaderActions [seFeDataEmphasis]="'high'" (click)="addDiscount()">
      {{ 'DISCOUNTS.LIST_VIEW.action' | translate }}
    </se-fe-button>
  </se-fe-header>

  <se-fe-card seFePageContent>
    <se-fe-card-content>
      <se-fe-spinner [seFeDataSize]="900" [seFeDataCenter]="true" *ngIf="pageLoading"></se-fe-spinner>

      <div [hidden]="hideEmptyState">
        <se-fe-empty-state [seFeDataTitle]="'DISCOUNTS.LIST_VIEW.EMPTY_STATE.title' | translate">
          <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_discount" seFeDataSize="950"></se-fe-icon>
          {{ 'DISCOUNTS.LIST_VIEW.EMPTY_STATE.description' | translate }}
          <se-fe-button seFeEmptyStateAction [seFeDataEmphasis]="'high'" [routerLink]="'new'">
            {{ 'DISCOUNTS.LIST_VIEW.EMPTY_STATE.action' | translate }}
          </se-fe-button>
        </se-fe-empty-state>
      </div>

      <div class="table-container" [hidden]="!hideEmptyState || pageLoading">
        <app-quick-filters [disableFilter]="discountDataLoading"></app-quick-filters>
        <hr />

        <se-fe-toolbar
          [seFeDataQuery]="searchTerm"
          (seFeQueryChange)="search($event)"
          [seFeDataSearchDebounce]="1000"></se-fe-toolbar>

        <se-fe-spinner
          *ngIf="discountDataLoading"
          [seFeDataSize]="900"
          [seFeDataCenter]="true"
          class="se-fe-spinner--discountDataLoading"></se-fe-spinner>

        <div [hidden]="discountData.length || discountDataLoading">
          <se-fe-empty-state [seFeDataTitle]="'EMPTY_STATE.NO_RESULTS.title' | translate">
            <se-fe-icon
              seFeEmptyStateIcon
              seFeDataName="search_thin"
              seFeDataSize="950"
              seFeDataColor="gray-900"></se-fe-icon>
            {{ 'EMPTY_STATE.NO_RESULTS.description' | translate }}
          </se-fe-empty-state>
        </div>

        <div [hidden]="!discountData.length || discountDataLoading">
          <table seFeTable seFeTableSort [seFeDataFixedLayout]="true">
            <ng-container seFeColumnDef="name">
              <th seFeDataRef="name" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.name' | translate }}
              </th>
              <td seFeCell class="discount-name" *seFeCellDef="let row">
                <a [routerLink]="row.id + '/detail'">{{ row.name }}</a>
              </td>
            </ng-container>

            <ng-container seFeColumnDef="discount_value">
              <th seFeDataRef="discount_value" seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.value' | translate }}
              </th>
              <td seFeCell *seFeCellDef="let row">{{ this.discountService.getValue(row) }}</td>
            </ng-container>

            <ng-container seFeColumnDef="starts_at">
              <th seFeDataRef="starts_at" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.dates' | translate }}
              </th>
              <td seFeCell *seFeCellDef="let row">{{ this.discountService.getActiveDates(row) }}</td>
            </ng-container>

            <ng-container seFeColumnDef="discount_style">
              <th seFeDataRef="discount_style" seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.type' | translate }}
              </th>
              <td seFeCell *seFeCellDef="let row">{{ this.discountService.getDiscountStyle(row) }}</td>
            </ng-container>

            <ng-container seFeColumnDef="code_max_usage">
              <th seFeDataRef="code_max_usage" seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.usage' | translate }}
              </th>
              <td seFeCell *seFeCellDef="let row">{{ this.discountService.getUsage(row) }}</td>
            </ng-container>

            <ng-container seFeColumnDef="used_code_count">
              <th seFeDataRef="used_code_count" seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.used' | translate }}
              </th>
              <td seFeCell *seFeCellDef="let row">{{ row.used_code_count }}</td>
            </ng-container>

            <ng-container seFeColumnDef="status">
              <th seFeDataRef="status" seFeHeaderCell *seFeHeaderCellDef>
                {{ 'DISCOUNTS.LIST_VIEW.COLUMNS.status' | translate }}
              </th>
              <td seFeCell *seFeCellDef="let row">
                <se-fe-chip
                  [seFeDataText]="discountService.getDiscountStatus(row) | titlecase"
                  [seFeDataStyle]="'DISCOUNTS.STATUS.' + discountService.getDiscountStatus(row) | translate"
                  seFeDataIconLeft="dot_fill"></se-fe-chip>
              </td>
            </ng-container>

            <ng-container seFeColumnDef="delete">
              <th seFeHeaderCell *seFeHeaderCellDef></th>
              <td seFeCell *seFeCellDef="let row">
                <se-fe-icon-button *ngIf="!row.used_code_count" (click)="confirmDelete($event, row.id, row.name)">
                  <se-fe-icon
                    seFeDataName="trash"
                    seFeDataSize="700"
                    title="Delete"
                    [seFeDataAriaLabel]="'delete'"></se-fe-icon>
                </se-fe-icon-button>
              </td>
            </ng-container>

            <tr seFeHeaderRow *seFeHeaderRowDef="displayedColumns.value; sticky: false"></tr>
            <tr seFeRow *seFeRowDef="let row; columns: displayedColumns.value"></tr>
          </table>

          <se-fe-pagination
            [seFeDataPaginationOptions]="paginationOptions"
            (seFePaginationChange)="handlePagination($event)"></se-fe-pagination>
        </div>
      </div>
    </se-fe-card-content>
  </se-fe-card>
</se-fe-page>
