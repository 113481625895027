import { Injectable } from '@angular/core';
import { DiscountService } from '../../services/discount.service';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadDiscount, loadDiscountFailure, loadDiscountSuccess } from './edit-discounts.actions';
import { Store } from '@ngrx/store';
import { selectRouteParam, selectSelectedDiscountId } from '../../../state/router.selectors';
import { from, of } from 'rxjs';

@Injectable()
export class EditDiscountsEffects {
  loadDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDiscount),
      withLatestFrom(this.store.select(selectSelectedDiscountId)),
      switchMap(([, discountId]) =>
        from(this.discountsApi.find(discountId)).pipe(
          map((discount) => loadDiscountSuccess({ discount })),
          catchError((error) => of(loadDiscountFailure({ error })))
        )
      )
    )
  );

  loadDiscountsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDiscountFailure),
      tap((action) => console.error(action.error))
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private discountsApi: DiscountService
  ) {}
}
