import { Routes } from '@angular/router';
import { SeFeFrameRouterService } from 'se-fe-frame-router';
import { SeFeTranslationsLoader } from 'se-fe-translations';
import { routes as DiscountRoutes } from './discounts/routes';
import { LaunchDarklyService } from './discounts/services/launch-darkly.service';
// Page components
import { NotFoundComponent } from './not-found/not-found.component';
import { routes as ItemRoutes } from './sale-items/routes';

export const routes: Routes = [
  {
    path: '',
    data: {
      frameRouterTitle: 'Not Found',
      pageDepth: 'NotFound'
    },
    canActivate: [
      SeFeFrameRouterService,
      SeFeTranslationsLoader
    ],
    children: [
      {
        path: 'items',
        data: {
          frameRouterTitle: 'Sale Items',
          pageDepth: 'HQ.SaleItems'
        },
        canActivate: [ SeFeTranslationsLoader, LaunchDarklyService ],
        children: ItemRoutes
      },
      {
        path: 'discounts',
        data: {
          frameRouterTitle: 'Discounts',
          pageDepth: 'HQ.Discounts'
        },
        canActivate: [ SeFeTranslationsLoader , LaunchDarklyService],
        children: DiscountRoutes
      },
      { path: '**', component: NotFoundComponent, data: { pageDepth: 'NotFound' } },
    ]
  }
];
