import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as LD from 'launchdarkly-js-client-sdk';
import { LaunchDarklyClient } from 'se-launchdarkly-js';
import { environment } from '../../../environments/environment';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';

@Injectable({ providedIn: 'root' })
export class LaunchDarklyService {
  public canActivate = this.loadClient;
  public canActivateChild = this.loadClient;
  private client: LD.LDClient;

  constructor(@Inject(CURRENT_ORG_ID) private currentOrgId: number) {
    // noop
  }

  public enabled(variation: string): boolean {
    return this.variation(variation, false);
  }

  public disabled(variation: string): boolean {
    return !this.variation(variation, false);
  }

  public variation(variation: string, defaultValue: any): boolean {
    return this.client ? this.client.variation(variation, defaultValue) : false;
  }

  public async loadClient(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.client) {
      try {
        this.client = await LaunchDarklyClient.client(
          environment.launchDarklyClientId,
          environment.apiUri,
          this.currentOrgId
        );
      } catch (e) {
        console.error('LaunchDarkly client could not be created');
        console.error(e);
      }
    }
    return true;
  }
}
