import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';
import { ItemVariation } from '../../sale-items/models/item-variation.model';
import { ItemVariationService } from '../../sale-items/services/item-variation.service';
import { Discount } from '../models/discount.model';
import { DiscountFormService } from '../services/discount-form.service';
import { loadDiscount } from './state/edit-discounts.actions';
import { selectDiscount } from './state/edit-discounts.selectors';

@Component({
  selector: 'app-edit-discounts-v2',
  templateUrl: './edit-discounts-v2.component.html',
  styleUrls: ['./edit-discounts-v2.component.scss'],
})
export class EditDiscountsV2Component implements OnInit {
  @Input() public discount: Discount;
  discount$: Observable<Discount>;
  editDiscountForm: FormGroup;

  // What do you want to name this discount?
  editDiscountNameConfig = {
    label: 'Discount name',
    errorMessages: {
      required: 'Please enter a discount name',
      minlength: 'Please enter a min length of 2 characters',
      maxlength: 'Discount name cannot be more than 64 characters',
    },
  };

  // What is the value of the discount?
  discountValueAmountConfig = {
    label: 'Amount',
    errorMessages: {
      required: 'Please enter a dollar amount',
      min: 'Please enter an amount of at least $1',
      pattern: 'Please enter a valid amount',
      max: 'Discount amount cannot exceed 100',
    },
  };

  discountValueTypeOptionsConfig = {
    label: 'Select dollars off or percent off',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };
  discountValueTypeOptions = [
    { label: '$ dollars off', value: 'amount' },
    { label: '% percent off', value: 'percentage' },
  ];
  discountValueMaxButton = false;
  discountValueMaxField = false;

  discountValueMaxConfig = {
    label: 'Maximum $ dollars off',
    required: true,
    errorMessages: {
      required: 'Please enter a dollar amount',
      min: 'Please enter an amount of at least $1',
      pattern: 'Please enter a valid dollar amount',
    },
  };

  // How do you want to apply the discount?
  applyDiscountTypeOptionsConfig = {
    label: 'How do you want to apply the discount?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };
  applyDiscountTypeOptions = [
    {
      label: 'Discount the cart subtotal',
      value: 'subtotal',
    },
    {
      label: 'Discount the sale items you choose',
      value: 'saleItems',
    },
  ];

  applyDiscountSaleItemButton = false;

  applyDiscountItemOptionsConfig = {
    label: 'How many sale items do you want to discount per order?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };
  applyDiscountItemOptions = [
    {
      label: 'One (1) lowest price eligible sale item',
      value: 'oneItem',
    },
    {
      label: 'All eligible sale items in the order',
      value: 'allItems',
    },
    {
      label: 'A set amount',
      value: 'setAmount',
    },
  ];

  applyDiscountSetAmountField = false;

  applyDiscountSetAmountConfig = {
    label: 'Enter a set amount',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an amount',
      pattern: 'Please enter a valid item amount',
    },
  };

  // What are the requirements to use this discount?
  discountUseRequirementsOptionsConfig = {
    label: 'What are the requirements to use this discount?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };
  discountUseRequirementsOptions = [
    {
      label: 'None',
      value: 'noRequirements',
    },
    {
      label: 'Spend a minimum amount ($) in an order',
      value: 'minimumSpend',
    },
    {
      label: 'Buy a minimum number (#) of sale items',
      value: 'minimumItems',
    },
  ];

  minimumSpendAmountFieldVisible = false;
  minItemsFieldVisible = false;

  minimumSpendAmountFieldConfig = {
    label: 'Minimum spend amount',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an amount',
      pattern: 'Please enter a valid dollar amount',
      min: 'Please enter an amount of at least $1',
    },
  };

  minimumItemsQtyFieldConfig = {
    label: 'Minimum quantity',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an amount',
      pattern: 'Please enter a valid item amount',
    },
  };

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: number,
    private fb: FormBuilder,
    private discountFormService: DiscountFormService,
    private itemVariationService: ItemVariationService,
    private store: Store
  ) {
    this.store.dispatch(loadDiscount());

    this.discount$ = this.store.select(selectDiscount);
  }

  ngOnInit(): void {
    this.createForm();
    this.discountValueTypeChange();
    this.handleApplyDiscountTypeChange();
    this.handleDiscountUseRequirementsChange();
    this.editDiscountForm.get('fromDate').valueChanges.subscribe(() => {
      this.updateExcludeDates();
    });

    this.editDiscountForm.get('toDate').valueChanges.subscribe(() => {
      this.updateExcludeDates();
    });

    this.editDiscountForm
      .get('saleItemsPerOrderSelections')
      .valueChanges.subscribe((selectedItems: ItemVariation[]) => {
        this.updateDiscountedItemsTable(selectedItems);
      });

    this.editDiscountForm
      .get('saleItemsRequiredSelections')
      .valueChanges.subscribe((selectedItems: ItemVariation[]) => {
        this.updateRequiredItemsTable(selectedItems);
      });

    this.discount$.subscribe((discount) => {
      if (discount) {
        this.bindFormControls(discount);
      }
    });
  }

  private createForm(): void {
    this.editDiscountForm = this.fb.group(
      {
        // What do you want to name this discount?
        discountName: ['', [Validators.required, Validators.maxLength(64), Validators.minLength(2)]],
        // What is the value of the discount?
        discountValueAmount: [
          '',
          [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'), Validators.min(1)],
        ],
        discountValueType: ['', [Validators.required]],
        discountValueMaxOff: ['', [Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'), Validators.min(1)]],

        // How do you want to apply the discount?
        applyDiscountTypeRadios: ['', [Validators.required]],
        saleItemsPerOrderRadios: ['', [Validators.required]],
        saleItemsPerOrderSelections: ['', [Validators.required]],
        setAmountQtyField: ['', [Validators.required, Validators.pattern(/^0*[1-9]\d*$/), Validators.min(1)]],

        // What are the requirements to use this discount?
        discountUseRequirementsRadios: ['', [Validators.required]],
        minimumSpendAmountField: [
          '',
          [
            Validators.required,
            Validators.min(1),
            Validators.pattern(/^\d+(?:\.\d{0,2})?$/),
            Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
          ],
        ],
        saleItemsRequiredSelections: ['', [Validators.required]],
        minimumItemsQtyField: ['', [Validators.required, Validators.pattern(/^0*[1-9]\d*$/), Validators.min(1)]],

        // When will this discount be available?
        fromDate: [this.getTodaysDate(), Validators.required],
        toDate: ['', Validators.required],
      },
      {
        validators: this.dateRangeValidator('fromDate', 'toDate'),
      }
    );

    this.discountValueTypeChange();
  }

  editDiscountSubmit(): void {
    if (this.editDiscountForm.valid) {
      console.log('editDiscountForm', this.editDiscountForm.value);
    }
  }

  /**
   * Validates the maximum discount value.
   *
   * @param max - The maximum value allowed for the discount.
   * @returns A validator function that checks if the value is a valid discount.
   */
  maxDiscountValidator(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseFloat(control.value);
      if (isNaN(value) || value > max) {
        return { max: { maxValue: max, actualValue: value } };
      }
      return null;
    };
  }

  /**
   * Handles the change event of the discount value type.
   * Updates the UI based on the selected value.
   */
  discountValueTypeChange(): void {
    this.editDiscountForm.get('discountValueType').valueChanges.subscribe((value) => {
      const discountValueAmountControl = this.editDiscountForm.get('discountValueAmount');
      const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

      if (value === 'percentage') {
        this.discountValueMaxButton = true; // Show the "Maximum $ dollars off" button
        discountValueAmountControl.setValidators([
          Validators.required,
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
          Validators.min(1),
          this.maxDiscountValidator(100),
        ]);
      } else if (value === 'dollars') {
        this.discountValueMaxButton = false; // Hide the "Maximum $ dollars off" button
        this.discountValueMaxField = false;
        discountValueMaxOffControl.clearValidators();
        discountValueAmountControl.setValidators([
          Validators.required,
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
          Validators.min(1),
        ]);
      }

      discountValueAmountControl.updateValueAndValidity();
      discountValueMaxOffControl.updateValueAndValidity();
    });
  }

  /**
   * Toggles the discountValueMaxField property and clears the discountValueMaxOff form control if the field is toggled off.
   */
  discountValueMaxToggle(): void {
    const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

    this.discountValueMaxField = !this.discountValueMaxField;

    if (this.discountValueMaxField) {
      discountValueMaxOffControl.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(1),
        this.maxDiscountValidator(100),
      ]);
      this.discountValueMaxButton = false; // Hide the "Maximum $ dollars off" button
    } else {
      discountValueMaxOffControl.clearValidators();
      discountValueMaxOffControl.setValue(''); // Clear the value if not required
      this.discountValueMaxButton = true; // Show the "Maximum $ dollars off" button
    }

    discountValueMaxOffControl.updateValueAndValidity();
  }

  /**
   * Handles the change event of the applyDiscountTypeRadios form control.
   * Subscribes to the valueChanges event and updates the component's state accordingly.
   * If the selected value is not 'saleItems', it resets the saleItemsPerOrderRadios and applyDiscountSetAmountField.
   * If the selected value is not 'setAmount', it resets the setAmountQtyField.
   */
  handleApplyDiscountTypeChange(): void {
    this.editDiscountForm.get('applyDiscountTypeRadios').valueChanges.subscribe((value) => {
      this.applyDiscountSaleItemButton = value === 'saleItems';
      if (value !== 'saleItems') {
        this.editDiscountForm.get('saleItemsPerOrderRadios').setValue('');
        this.applyDiscountSetAmountField = false;
      }
    });

    this.editDiscountForm.get('saleItemsPerOrderRadios').valueChanges.subscribe((value) => {
      this.applyDiscountSetAmountField = value === 'setAmount';
      if (value !== 'setAmount') {
        this.editDiscountForm.get('setAmountQtyField').setValue('');
      }
    });
  }

  /**
   * Handles the change event of the discount use requirements radios.
   * Updates the visibility of the minimum spend amount field and the minimum items quantity field based on the selected value.
   * If the minimum spend amount field is not visible, it sets its value to an empty string.
   * If the minimum items quantity field is not visible, it sets its value to an empty string.
   */
  handleDiscountUseRequirementsChange(): void {
    this.editDiscountForm.get('discountUseRequirementsRadios').valueChanges.subscribe((value) => {
      this.minimumSpendAmountFieldVisible = value === 'minimumSpend';
      this.minItemsFieldVisible = value === 'minimumItems';

      if (!this.minimumSpendAmountFieldVisible) {
        this.editDiscountForm.get('minimumSpendAmountField').setValue('');
      }
      if (!this.minItemsFieldVisible) {
        this.editDiscountForm.get('minimumItemsQtyField').setValue('');
      }
    });
  }

  private getTodaysDate(): string {
    return DateTime.now().toISODate();
  }

  /**
   * Validates the date range between two form controls.
   *
   * @param fromDateKey - The key of the form control representing the start date.
   * @param toDateKey - The key of the form control representing the end date.
   * @returns A validator function that checks if the end date is greater than the start date.
   */
  dateRangeValidator(fromDateKey: string, toDateKey: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const fromDate = formGroup.get(fromDateKey).value;
      const toDate = formGroup.get(toDateKey).value;

      if (!fromDate || !toDate) {
        return null;
      }

      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      return toDateObj > fromDateObj ? null : { dateRangeInvalid: true };
    };
  }

  excludeDatesAfterEnd(): { start: string }[] {
    if (!this.editDiscountForm.value.toDate) {
      return [];
    }
    const endDate = DateTime.fromFormat(this.editDiscountForm.value.toDate, this.discountFormService.UI_DATE_FORMAT);
    return [
      {
        start: endDate.plus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT),
      },
    ];
  }

  excludeDatesBeforeStart(): { end: string }[] {
    if (!this.editDiscountForm.value.fromDate) {
      return [];
    }
    const startDate = DateTime.fromFormat(
      this.editDiscountForm.value.fromDate,
      this.discountFormService.UI_DATE_FORMAT
    );
    return [
      {
        end: startDate.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT),
      },
    ];
  }

  bindFormControls(discount: Discount): void {
    this.editDiscountForm.get('discountName').setValue(discount.name);
    this.editDiscountForm.get('discountValueAmount').setValue(discount.discount_value);
    this.editDiscountForm.get('discountValueType').setValue(discount.discount_type);
    this.editDiscountForm.get('discountValueMaxOff').setValue(discount.maximum_discount_value);

    if (discount.starts_at) {
      const fromDate = DateTime.fromISO(discount.starts_at).toFormat(this.discountFormService.UI_DATE_FORMAT);
      this.editDiscountForm.get('fromDate').setValue(fromDate);
    }
    if (discount.expires_at) {
      const toDate = DateTime.fromISO(discount.expires_at).toFormat(this.discountFormService.UI_DATE_FORMAT);
      this.editDiscountForm.get('toDate').setValue(toDate);
    }

    const discountType = discount.discount_scope === 'Order' ? 'subtotal' : 'saleItems'; // 'Item' or 'Order'?
    this.editDiscountForm.get('applyDiscountTypeRadios').setValue(discountType);

    const discountedItems = discount.discounted_items;
    this.editDiscountForm.get('saleItemsPerOrderSelections').setValue(discountedItems);

    this.editDiscountForm
      .get('saleItemsPerOrderRadios')
      .setValue(this.getSaleItemsPerOrderValue(discount.max_eligible_items));
    this.editDiscountForm.get('setAmountQtyField').setValue(discount.max_eligible_items || null);

    const discountUseRequirement =
      discount.requirement_type === 'None'
        ? 'noRequirements'
        : discount.requirement_type === 'Quantity'
        ? 'minimumItems'
        : 'minimumSpend';
    this.editDiscountForm.get('discountUseRequirementsRadios').setValue(discountUseRequirement);

    const requiredItems = discount.required_items;
    this.editDiscountForm.get('saleItemsRequiredSelections').setValue(requiredItems);

    this.editDiscountForm.get('minimumItemsQtyField').setValue(discount.required_items_quantity);
    this.editDiscountForm.get('minimumSpendAmountField').setValue(discount.minimum_sale_total_cents / 100);
  }

  getSaleItemsPerOrderValue(maxEligibleItems: number | null): string | null {
    if (maxEligibleItems === null) {
      return null;
    }
    switch (maxEligibleItems) {
      case 0:
        return 'allItems';
      case 1:
        return 'oneItem';
      default:
        return 'setAmount';
    }
  }

  /**
   * Updates the exclude dates.
   */
  public updateExcludeDates(): void {}

  /**
   * Updates the discounted items in the form.
   */
  private updateDiscountedItemsTable(selectedItems: any): void {
    if (!Array.isArray(selectedItems)) {
      console.error('selectedItems is not an array:', selectedItems);
      return;
    }

    this.editDiscountForm
      .get('saleItemsPerOrderSelections')
      .setValue(selectedItems.map((item: ItemVariation) => item.id));

    const discountedItemIds = this.editDiscountForm.get('saleItemsPerOrderSelections').value;
    if (discountedItemIds) {
      const queryParams = {
        id_list: discountedItemIds,
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };

      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        console.log('sale items', response.result);
      });
    }
  }

  /**
   * Updates the required items in the form.
   */
  private updateRequiredItemsTable(selectedItems: any): void {
    if (!Array.isArray(selectedItems)) {
      console.error('selectedItems is not an array:', selectedItems);
      return;
    }

    this.editDiscountForm
      .get('saleItemsRequiredSelections')
      .setValue(selectedItems.map((item: ItemVariation) => item.id));

    const requiredItemIds = this.editDiscountForm.get('saleItemsRequiredSelections').value;
    if (requiredItemIds) {
      const queryParams = {
        id_list: requiredItemIds,
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };

      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        console.log('required items', response.result);
      });
    }
  }
}
