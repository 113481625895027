// Angular imports
import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

// Models
import { ApiResponse } from '../models/api-response.model';
import { ItemVariation } from '../models/item-variation.model';
import { Item } from '../models/item.model';
import { MembershipDefinition } from '../models/membership-definition.model';
import { SaleItem } from '../models/sale-item.model';
import { SalesSummary } from '../models/sales-summary.model';

// Custom services
import { SeAnalyticsService } from 'se-analytics';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { AppExternalLinkService } from 'src/app/services/external-link.service';
import { LoadScriptsService } from 'src/app/services/load-scripts.service';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';
import { PagingService } from '../pagination/paging.service';
import { ItemVariationService } from '../services/item-variation.service';
import { ItemService } from '../services/item.service';
import { MembershipDefinitionService } from '../services/membership-definition.service';
import { SaleItemService } from '../services/sale-item.service';
import { StoreStatusService } from '../services/store-status.service';
import { SurveyResultsService } from '../services/survey-results.service';
import { LaunchDarklyService } from '../../discounts/services/launch-darkly.service';
import { PriceSchedule } from '../models/price-schedule.model';
import { PriceScheduleService } from '../services/price-schedule.service';

@Component({
  selector: 'app-item-detail',
  styleUrls: ['./item-detail.component.scss'],
  templateUrl: './item-detail.component.html',
})
export class ItemDetailComponent implements OnInit, OnDestroy {
  defaultVariation: ItemVariation;
  hasSaleItems: boolean;
  variationHasSku: boolean;
  variationStats: any = {};
  item: Item;
  itemId = this.activatedRoute.snapshot.params.saleItemId;
  saleItems: SaleItem[];
  loadingSaleItems = true;
  showOverlay: boolean;
  loadingItem = true;
  salesSummary: string;
  showTaxCode: boolean;
  queryParamSubscription: Subscription;
  storeStatusSubscription: Subscription;
  descriptionTitle: string;
  membershipLinkText: string;
  membershipLink: string;
  membershipEditText = 'To edit, visit ';
  membershipDetailsText = 'membership details';
  externalBaseUrl = environment.snFrontendBaseUrl;
  upfrontAmountSettingText: string;
  showUpfrontAmountText = false;
  showItemModal = false;
  variationDescription: string;
  earlyLatePricingEnabled = false;
  priceSchedules: Array<PriceSchedule>;

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: string,
    private activatedRoute: ActivatedRoute,
    private membershipDefinitionService: MembershipDefinitionService,
    private itemService: ItemService,
    private itemVariationService: ItemVariationService,
    private saleItemService: SaleItemService,
    private currencyPipe: CurrencyPipe,
    private pagingService: PagingService,
    private storeStatusService: StoreStatusService,
    private toastService: SeFeToastService,
    public externalLinkService: AppExternalLinkService,
    private seAnalyticsService: SeAnalyticsService,
    private surveyResultsService: SurveyResultsService,
    private loadScriptsService: LoadScriptsService,
    private launchDarkly: LaunchDarklyService,
    private priceScheduleService: PriceScheduleService
  ) {
    this.earlyLatePricingEnabled = this.launchDarkly.enabled('early-late-pricing');
  }

  ngOnInit() {
    this.loadScriptsService.loadScripts('saleItem');
    this.loadItem();
    this.subscribeToRouteParams();
    this.subscribeToStoreStatus();
    if (!this.storeStatusService.isSet) {
      this.storeStatusService.setStoreStatus(this.currentOrgId);
    }
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'SaleItems',
      depth3: 'Detail',
    });
  }

  ngOnDestroy() {
    this.pagingService.unsubscribe();
    this.queryParamSubscription.unsubscribe();
    this.storeStatusSubscription.unsubscribe();
  }

  loadItem(): void {
    this.loadingItem = true;
    forkJoin([
      this.itemService.getItem(this.itemId),
      this.itemVariationService.getItemVariations(this.combinedVariationApiParams()),
    ]).subscribe(
      ([response, statsResponse]) => {
        this.loadVariationStatsSuccess(statsResponse);
        this.loadItemSuccess(response);
      },
      ([error, variationError]) => {
        if (error) {
          this.loadItemError(error);
        }
        if (variationError) {
          this.loadItemError(variationError);
        }
      }
    );
  }

  loadItemSuccess(response: ApiResponse<Item>): void {
    this.item = new Item(response.result);
    this.defaultVariation = this.item.getDefaultVariation();
    this.variationHasSku = this.item.variations.some((variation: ItemVariation) => !!variation.sku);
    this.setMembershipInfo();
    this.showUpfrontAmountText = this.defaultVariation.price_cents > 0;
    if (this.showUpfrontAmountText) {
      this.upfrontAmountSettingText = this.itemVariationService.upfrontAmountSettingText(this.defaultVariation);
    }

    if (this.earlyLatePricingEnabled) {
      this.loadPriceSchedules();
    } else {
      this.loadingItem = false;
    }

    this.variationDescription = this.item.variations.length > 1 ? 'includes_all_variations' : 'null';
  }

  loadVariationStatsSuccess(response: ApiResponse<ItemVariation[]>): void {
    response.result.forEach((variationStat: any) => {
      this.variationStats[variationStat.id] = variationStat;
    });
  }

  loadPriceSchedules() {
    const param = { item_variation_uuid: this.defaultVariation.uuid };
    this.priceScheduleService.getPriceSchedules(param).subscribe(
      (response) => {
        this.priceSchedules = response.result;
        this.loadingItem = false;
      },
      () => {
        this.loadingItem = false;
      }
    );
  }

  setMembershipInfo(): void {
    if (this.item && this.item.membership) {
      this.membershipDefinitionService.getMembershipDefinition(this.item.uuid, this.currentOrgId).subscribe(
        (response) => this.showDescription(response.result, this.currentOrgId),
        () => this.showDescriptionTitleOnly()
      );
    }
  }

  showDescription(result: MembershipDefinition[], orgId: string): void {
    if (result.length) {
      this.membershipLink = this.getMembershipUrl(result[0].id, orgId);
      this.descriptionTitle = this.membershipEditText;
      this.membershipLinkText = this.membershipDetailsText;
    } else {
      this.showDescriptionTitleOnly();
    }
  }

  showDescriptionTitleOnly(): void {
    this.descriptionTitle = this.membershipEditText + this.membershipDetailsText;
  }

  hideEditAction(): boolean {
    return this.loadingSaleItems ? true : this.item.membership;
  }

  getMembershipUrl(id: string, orgId: string): string {
    return this.membershipDefinitionService.getMembershipUrl(id, orgId);
  }

  loadItemError(error: Error): void {
    throw error;
  }

  subscribeToRouteParams(): void {
    this.queryParamSubscription = this.activatedRoute.queryParamMap.subscribe(() => {
      this.loadSaleItems();
    });
  }

  combinedVariationApiParams(): Params {
    const params = isNaN(this.itemId) ? { item_uuid: this.itemId } : { item_id: this.itemId };
    return this.pagingService.toApiParams(params);
  }

  combinedApiParams(): Params {
    const params = {
      item_id: this.itemId,
      sold: true,
    };
    return this.pagingService.toApiParams(params);
  }

  loadSaleItems(): void {
    this.showOverlay = true;
    this.saleItemService.getSaleItems(this.combinedApiParams()).subscribe((response: ApiResponse<SaleItem[]>) => {
      this.saleItems = response.result.map((saleItem) => new SaleItem(saleItem));
      this.pagingService.collect(response.metadata.pagination);
      this.generateSalesSummary(response.metadata.summary);
      this.hasSaleItems = !!this.saleItems.length;
      this.loadingSaleItems = false;
      this.showOverlay = false;
    });
  }

  public async getProfileUrl(saleItem: SaleItem): Promise<void> {
    if (saleItem.profileUrl) {
      return;
    }

    const { metadata } = saleItem;
    if (metadata?.memberships) {
      saleItem.profileUrl = this.externalLinkService.getMemberUrl(metadata.memberships.persona_ids[0]);
    } else if (metadata?.survey_result) {
      const data = await this.surveyResultsService.get(metadata.survey_result.id).toPromise();
      saleItem.profileUrl = this.externalLinkService.getMemberUrl(data.result.persona_id);
    }
  }

  generateSalesSummary(summaryObj: SalesSummary): void {
    const currency = this.getCurrency(this.saleItems);
    const totalAmountInDollars = summaryObj.total_amount / 100;
    const formattedTotalAmount = this.currencyPipe.transform(totalAmountInDollars, currency, 'symbol', '1.2-2');
    this.salesSummary = `${summaryObj.total_quantity} sold • ${formattedTotalAmount} total`;
  }

  public onSuccess(item: Item): void {
    this.toastService.addToast({
      type: SeFeToastTypes.Success,
      message: `"${item.name}" sale item updated`,
    });
    this.loadItem();
  }

  public toggleItemModal(): void {
    this.showItemModal = !this.showItemModal;
  }

  private subscribeToStoreStatus(): void {
    this.storeStatusSubscription = this.storeStatusService.$storeStatus.subscribe((storeStatus) => {
      this.showTaxCode = storeStatus.taxable;
    });
  }

  private getCurrency(saleItems: SaleItem[]): string {
    return saleItems.length ? saleItems[0].currency : 'USD';
  }

  public getFinancialReportURL(): string {
    return `${environment.snFrontendBaseUrl}/org/${this.currentOrgId}/financial_reports/sale-items/${this.itemId}`;
  }
}
