<se-fe-page SeFeDataWidth="full" seFeDataBackLink="../.." seFeDataBackText="back">
  <se-fe-header seFePageHeader seFeDataTitle="Edit Discounts" size="750"></se-fe-header>
  <div seFePageContent>
    <se-fe-grid seFeGridOpts="center">
      <se-fe-grid-cell seFeGridOpts="1/1@mobile 1/2@tablet 1/3@desktop">
        <se-fe-grid seFeGridOpts>
          <se-fe-grid-cell seFeGridOpts>
            <se-fe-card>
              <se-fe-card-header seFeDataTitle="Summary"></se-fe-card-header>
              <se-fe-card-content>
                <se-fe-grid seFeGridOpts>
                  <se-fe-grid-cell seFeGridOpts>
                    <div class="summary-text">
                      <p>
                        This automatic discount is named Automatic Discount - Used Automation Testing - DO NOT EDIT.
                      </p>
                      <p>The value of the discount is 100% off. The discount applies to the cart subtotal.</p>
                      <p>This discount can be used an unlimited number of times after Jun 16, 2023.</p>
                    </div>
                  </se-fe-grid-cell>
                </se-fe-grid>
              </se-fe-card-content>
            </se-fe-card>
          </se-fe-grid-cell>
        </se-fe-grid>
      </se-fe-grid-cell>
      <se-fe-grid-cell seFeGridOpts="1/1@mobile 1/2@tablet 2/3@desktop">
        <form [formGroup]="editDiscountForm" (ngSubmit)="editDiscountSubmit()">
          <!-- What do you want to name this discount? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="What do you want to name this discount?"
                  class="edit-name-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="editDiscountNameConfig">
                        <se-fe-form-field-text
                          formControlName="discountName"
                          seFeDataAutoComplete="off"></se-fe-form-field-text>
                        <div seFeHelp>This name will appear in the checkout, on receipts, and in reports.</div>
                      </se-fe-form-field>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- What is the value of the discount? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card class="value-cards">
                <se-fe-card-header
                  seFeDataTitle="What is the value of the discount?"
                  class="edit-value-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountValueAmountConfig">
                        <se-fe-form-field-text
                          formControlName="discountValueAmount"
                          seFeDataType="number"
                          seFeDataAutoComplete="off"></se-fe-form-field-text>
                      </se-fe-form-field>

                      <se-fe-form-field [seFeDataConfig]="discountValueTypeOptionsConfig">
                        <se-fe-radios
                          formControlName="discountValueType"
                          [seFeDataOptions]="discountValueTypeOptions"></se-fe-radios>
                      </se-fe-form-field>

                      <se-fe-button
                        *ngIf="discountValueMaxButton"
                        seFeDataEmphasis="low"
                        (click)="discountValueMaxToggle()"
                        [seFeDataSnug]="true">
                        Maximum $ dollars off
                      </se-fe-button>
                      <div *ngIf="discountValueMaxField" class="discountValueMaxField">
                        <se-fe-form-field [seFeDataConfig]="discountValueMaxConfig">
                          <se-fe-form-field-text
                            formControlName="discountValueMaxOff"
                            seFeDataType="number"
                            seFeDataAutoComplete="off"></se-fe-form-field-text>
                        </se-fe-form-field>
                        <div *ngIf="editDiscountForm.get('discountValueType').value === 'percentage'">
                          {{ editDiscountForm.get('discountValueAmount').value }}% off, up to a maximum of ${{
                            editDiscountForm.get('discountValueMaxOff').value
                          }}
                        </div>
                        <se-fe-button seFeDataEmphasis="low" (click)="discountValueMaxToggle()" [seFeDataSnug]="true">
                          Remove maximum $ dollars off
                        </se-fe-button>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- How do you want to apply the discount? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="How do you want to apply the discount?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="applyDiscountTypeOptionsConfig">
                        <se-fe-radios
                          formControlName="applyDiscountTypeRadios"
                          [seFeDataOptions]="applyDiscountTypeOptions"></se-fe-radios>
                      </se-fe-form-field>
                      <!-- How do you want to apply the discount? -->
                      <div class="applyDiscountSaleItem" *ngIf="applyDiscountSaleItemButton">
                        <se-fe-form-control-label
                          seFeDataLabel="Which sale items do you want to discount?"></se-fe-form-control-label>

                        <app-sale-item-modal
                          id="item-selector"
                          [organizationId]="currentOrgId"
                          [selectedItemIds]="editDiscountForm.get('saleItemsPerOrderSelections').value"
                          (selectionSaved)="updateDiscountedItemsTable($event)"></app-sale-item-modal>

                        <se-fe-form-field [seFeDataConfig]="applyDiscountItemOptionsConfig">
                          <se-fe-radios
                            formControlName="saleItemsPerOrderRadios"
                            [seFeDataOptions]="applyDiscountItemOptions"></se-fe-radios>
                        </se-fe-form-field>

                        <!-- if setAmount is selected -->
                        <div *ngIf="applyDiscountSetAmountField">
                          <se-fe-form-control-label
                            seFeDataLabel="How many sale items do you want to discount per order?"
                            seFeDataFor="setAmountQtyField"></se-fe-form-control-label>
                          <se-fe-form-field [seFeDataConfig]="applyDiscountSetAmountConfig">
                            <se-fe-form-field-text
                              formControlName="setAmountQtyField"
                              seFeDataType="number"
                              seFeDataAutoComplete="off"></se-fe-form-field-text>
                          </se-fe-form-field>
                        </div>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- What are the requirements to use this discount? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="What are the requirements to use this discount?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountUseRequirementsOptionsConfig">
                        <se-fe-radios
                          formControlName="discountUseRequirementsRadios"
                          [seFeDataOptions]="discountUseRequirementsOptions">
                          <!-- Custom template for radio buttons -->
                          <ng-template let-option let-index="index" let-checked="checked">
                            <div>
                              {{ option.label }}
                            </div>
                            <!-- Conditionally render the minimumSpendAmountField after "Spend a minimum amount ($)" option -->
                            <div *ngIf="option.value === 'minimumSpend' && checked && minimumSpendAmountFieldVisible">
                              <se-fe-form-field [seFeDataConfig]="minimumSpendAmountFieldConfig">
                                <se-fe-form-field-text
                                  formControlName="minimumSpendAmountField"
                                  seFeDataType="number"
                                  seFeDataAutoComplete="off"></se-fe-form-field-text>
                              </se-fe-form-field>
                            </div>
                          </ng-template>
                        </se-fe-radios>
                      </se-fe-form-field>

                      <div *ngIf="minItemsFieldVisible" class="minItemsFieldVisible">
                        <se-fe-form-control-label
                          seFeDataLabel="Which sale items are required?"
                          seFeDataFor="saleItemsRequiredSelections"></se-fe-form-control-label>

                        <app-sale-item-modal
                          id="item-selector"
                          [organizationId]="currentOrgId"
                          [selectedItemIds]="editDiscountForm.get('saleItemsRequiredSelections').value"
                          (selectionSaved)="updateRequiredItemsTable($event)"></app-sale-item-modal>

                        <se-fe-form-control-label
                          seFeDataLabel="Minimum quantity"
                          seFeDataFor="minimumItemsQtyField"></se-fe-form-control-label>
                        <se-fe-form-field [seFeDataConfig]="minimumItemsQtyFieldConfig">
                          <se-fe-form-field-text
                            formControlName="minimumItemsQtyField"
                            seFeDataType="number"
                            seFeDataAutoComplete="off"></se-fe-form-field-text>
                        </se-fe-form-field>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- When will this discount be available? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="When will this discount be available?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountUseRequirementsOptionsConfig"></se-fe-form-field>
                      <div class="date-picker">
                        <se-fe-datepicker
                          seFeDataLabel="Start Date"
                          seFeDataHelpText="MM/DD/YYYY"
                          [seFeDataDateRangeIcon]="true"
                          formControlName="fromDate"
                          [seFeDataYearRange]="{ future: 5, past: 1 }"
                          [seFeDataExcludeDates]="excludeDatesAfterEnd()"
                          [seFeDataMessages]="{
                            required: 'date is required',
                            dateInvalid: 'date is invalid',
                            dateOutOfRange: 'date is unavailable'
                          }"></se-fe-datepicker>

                        <se-fe-datepicker
                          seFeDataLabel="End Date"
                          seFeDataHelpText="MM/DD/YYYY"
                          [seFeDataDateRangeIcon]="true"
                          formControlName="toDate"
                          [seFeDataYearRange]="{ future: 5, past: 1 }"
                          [seFeDataExcludeDates]="excludeDatesBeforeStart()"
                          [seFeDataMessages]="{
                            required: 'date is required',
                            dateInvalid: 'date is invalid',
                            dateOutOfRange: 'date is unavailable'
                          }"></se-fe-datepicker>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
                <se-fe-card-footer seFeDataBorder="full">
                  <div class="edit-discount-submit">
                    <se-fe-button seFeDataType="submit" seFeDataEmphasis="high">Next</se-fe-button>
                  </div>
                </se-fe-card-footer>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>
        </form>
      </se-fe-grid-cell>
    </se-fe-grid>
  </div>
</se-fe-page>
